import React from "react";
import { Grid, Column } from "@carbon/react";
import Industry from "../../components/industry";
import { Helmet } from "react-helmet";

const Industries = () => {
    return (
        <>
                <Helmet>
            <title> GeoPsy Research | Industries</title>
            <meta name="description" content="GeoPsy Research is the leading center for research and training on socio-spatial vulnerabilities, mental health, geospatial analytics, and surveying measurements" />
        </Helmet>
        <Grid className="landing-page" fullWidth>
        <Column lg={16} md={8} sm={4} className="landing-page__r2">
        <h1 style={{marginTop: 20, marginLeft: 20}}  className="landing-page__subheading">Industries</h1>
<p style={{marginLeft: 20}}  className="landing-page__p">
We inspire positive GIS and data analysis to diverse number of industries.
</p>
<Industry />
</Column>
</Grid>
</>
    )
}

export default Industries;