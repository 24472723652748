import {
    ThemeIcon,
    Text,
    SimpleGrid,
    Card,
    createStyles,
  } from '@mantine/core';
  import { IconTools, IconFireHydrant, IconDatabase, IconUserExclamation, IconBook2, IconWashTemperature1 } from '@tabler/icons';
  
  export const MOCKDATA = [
    {
      icon: IconTools,
      title: 'Architecture, Engineering and Construction',
      description:
        'Environmental management, Impact assessment, Engineering and design, construction management and asset management.',
    },
    {
      icon: IconFireHydrant,
      title: 'Natural Resource',
      description:
        'Agriculture, forestry, mining, petroleum and renewable sources of energy.',
    },
    {
      icon: IconDatabase,
      title: 'Conservation',
      description:
        'Land management, wetland dynamics, spatio-temporal analysis, degredation analysis and community based conservation.',
    },
    {
      icon: IconUserExclamation,
      title: 'Science and Mental Research',
      description:
        'Humanitarian assistance, advocacy and stakeholder engagement, feasibility tests, psychology and public safety overview.',
    },
    {
      icon: IconBook2,
      title: 'Education',
      description:
        'Mentorships, higher-education partnership, education overview.',
    },
    {
        icon: IconWashTemperature1,
        title: 'Water Utilities',
        description:
          'Water utilities overview.',
    },
  ];

  const useStyles = createStyles((theme) => ({
    wrapper: {
      margin: 10,
      marginBottom: 0
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      marginBottom: theme.spacing.md,
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: 28,
        textAlign: 'left',
      },
    },
  
    description: {
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        textAlign: 'left',
      },
    },

    card: {
      marginBottom: 20,
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
    },
  
    cardTitle: {
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: theme.fn.primaryColor(),
        width: 45,
        height: 2,
        marginTop: theme.spacing.sm,
      },
    },
  }));
  
  
  export function Feature({ icon: Icon, title, description }) {
    const { classes, theme} = useStyles();
    return (
        <Card key={title} shadow="md" radius="md" className={classes.card} p="xl">
        <ThemeIcon variant="light" size={40} radius={40}>
          <Icon size={20} stroke={1.5} />
        </ThemeIcon>
        <Text style={{ marginTop: theme.spacing.sm, marginBottom: 7 }} className={classes.cardTitle}>{title}</Text>
        <Text size="sm" color="dimmed" style={{ lineHeight: 1.6 }}>
          {description}
        </Text>
        </Card>
    );
  }

  
  
  export default function Industry() {
    const { classes, theme } = useStyles();
    const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);
  
    return (
      <div className={classes.wrapper}>
  
        <SimpleGrid
          mt={10}
          cols={3}
          spacing={theme.spacing.xl * 2}
          breakpoints={[
            { maxWidth: 980, cols: 2, spacing: 'xl' },
            { maxWidth: 755, cols: 1, spacing: 'xl' },
          ]}
        >
          {features}
        </SimpleGrid>
      </div>
    );
  }