import './App.scss';
import { Theme, Content } from '@carbon/react';
import AppHeader from './components/AppHeader';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './content/LandingPage';
import About from './content/About';
import Industries from './content/Industries';
import Products from './content/Products';
import Stories from './content/Stories';
import Support from './content/Support';
import Footer from './components/Footer';
import { Subscribe } from './components/Subscribe';

function App() {
  return (
    <>
    <Theme theme="g100">
      <AppHeader />
    </Theme>
    <Content>
    <Theme theme="g100">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about" component={About} />
        <Route exact path="/industries" component={Industries} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/stories" component={Stories} />
        <Route exact path="/support" component={Support} />
        <Route exact path="*" component={LandingPage} />
      </Switch>
    </Theme>
    </Content>
    <Theme theme="g100">
      <>
      <Subscribe />
      <Footer />
      </>
    </Theme>
    </>
  );
}

export default App;
