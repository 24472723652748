import {
    Card,
    Text,
    SimpleGrid,
    createStyles,
  } from '@mantine/core';
  import {  Icon3dCubeSphere, IconUsers, IconCode } from '@tabler/icons';
  
  export const MOCKDATA = [
    {
      icon: Icon3dCubeSphere,
      title: 'Online GIS Software',
      description:
        'Complete online GIS software as a service(SaaS) mapping platform.',
    },
    {
      icon: IconUsers,
      title: 'Consultancy',
      description:
        'An experienced team of experts in GIS, remote sensing and spatial data analysis.',
    },
    {
      icon: IconCode,
      title: 'Custom Software Development',
      description:
        'From data collection toolkits, data analysis and visualization toolkits to custom GIS software.',
    }
  ];

  const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: 10,
        marginBottom: 0,
    },

  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    marginBottom: 20,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
  }));
  
  
  export function Feature({icon: Icon, title, description}) {
    const { classes} = useStyles();
    return (
        <Card key={title} shadow="md" radius="md" className={classes.card} p="xl">
        <Icon size={50} stroke={2} />
        <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
          {title}
        </Text>
        <Text size="sm" color="dimmed" mt="sm">
          {description}
        </Text>
      </Card>
    );
  }
  
  
  export default function ProductList() {
    const { classes, theme } = useStyles();
    const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);
  
    return (
      <div className={classes.wrapper} >
        <SimpleGrid
          mt={10}
          cols={3}

          spacing={theme.spacing.xs}
          breakpoints={[
            { maxWidth: 980, cols: 2, spacing: 'xl' },
            { maxWidth: 755, cols: 2, spacing: 'xl' },
          ]}
        >
          {features}
        </SimpleGrid>
      </div>
    );
  }