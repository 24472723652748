import { createStyles, Anchor, Group, ActionIcon } from '@mantine/core';
import { IconBrandTwitter, IconBrandFacebook, IconBrandLinkedin } from '@tabler/icons';
import { HeaderName } from "@carbon/react";
import { Link } from 'react-router-dom';

const links = [
    {
      "link": "https://geopsyresearch.org/blogs/",
      "label": "Blog"
    },
    {
      "link": "https://geopsyresearch.org/blogs/",
      "label": "Careers & Learning"
    },
    {
        "link": "https://collect-v2.vercel.app",
        "label": "GeoPsy Collect"
    },
    {
        "link": "https://emita.vercel.app",
        "label": "E-mita"
    }
  ]

const useStyles = createStyles((theme) => ({
  footer: {

    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },
}));

export default function Footer() {
  const { classes } = useStyles();
  const items = links.map((link) => (
    <Anchor
      color="dimmed"
      key={link.label}
      href={link.link}
      sx={{ lineHeight: 1 }}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
      <HeaderName element={Link} to="/" prefix="">
          &copy;GeoPsy Research{" " + new Date().getFullYear()}
        </HeaderName>

        <Group className={classes.links}>{items}</Group>

        <Group spacing="xs" position="right" noWrap>
          <ActionIcon component='a' target="_blank" href='https://twitter.com/geopsy_research' size="lg" variant="default" radius="xl">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" component='a' target="_blank" href='https://www.facebook.com/geopsy.research' variant="default" radius="xl">
            <IconBrandFacebook size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon component='a' target="_blank" href='https://www.linkedin.com/company/geopsy-research/' size="lg" variant="default" radius="xl">
            <IconBrandLinkedin size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </div>
    </div>
  );
}