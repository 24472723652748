import React from "react";
import {
    Button,
    Grid,
    Column
  } from '@carbon/react';
import { Link } from "react-router-dom";
import { InfoCard, InfoSection } from "../../components/Info";
import { Globe, Application, ChartScatter } from '@carbon/react/icons';
import Partners from "../../components/Partners";
import { Helmet } from "react-helmet";
import { useViewportSize } from "@mantine/hooks";
import ProductList from "../../components/Products";

const LandingPage = () => {
    return(
        <>
                <Helmet>
            <title>GeoPsy Research | Mapping Minds</title>
            <meta name="description" content="GeoPsy Research is the leading center for research and training on socio-spatial vulnerabilities, mental health, geospatial analytics, and surveying measurements" />
        </Helmet>
          <Grid className="landing-page" fullWidth>
        <Column lg={16} md={8} sm={4} className="landing-page__r2">

        <Grid className="tabs-group-content">
        <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
          <h1 className="landing-page__subheading">Pioneering GIS<br /> with AI</h1>
          <p className="landing-page__p">
          Are you ready to revolutionize the way you approach geospatial research? 
          Our cutting-edge software solutions are
           designed to make your geospatial data analysis faster, more accurate, and more intuitive. 
           Whether you are a researcher, a business owner, or a government agency, 
           GeoPsy Research helps you to make sense of your data and improve your decision making. 

          </p>
          <Button as={Link} to="/about" >Learn more</Button>
        </Column>
      <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
      <img
          className="landing-page__illo"
          src={`${process.env.PUBLIC_URL}/tab-illo.png`}
          alt="Carbon illustration"
      />
      </Column>
      </Grid>
      </Column>
      </Grid>
    <InfoSection heading="Our Definition" className="landing-page__r3">
    <InfoCard
        heading="Data"
        body="
        <p>
        Our geospatial data and related services are designed to help our clients make informed decisions and solve complex problems related to geography and spatial relationships. Whether you need maps, spatial analysis, or custom geospatial solutions, we have the expertise and resources to meet your needs.
        </p>
        "
        icon={() => <ChartScatter size={32} />}
    />
    <InfoCard
        heading="Tools"
        body="
        <p>Our tools and technologies are designed to help our clients make the most of their geospatial data and solve complex problems related to geography and spatial relationships. Whether you need maps, spatial analysis, or custom geospatial solutions, we have the tools and expertise to meet your needs.</p>
        "
        icon={() => <Application size={32} />}
    />
    <InfoCard
        heading="Research"
        body="
        <p>Our commitment to research and innovation allows us to stay at the forefront of the geospatial field and provide our clients with the most advanced and accurate products and services available. This we do by collaborating with academic and industry partners, investing in R&D, participating in conferences and workshops and by conducting internal research.</p>
      </p>"
        icon={() => <Globe size={32} />}
    />
    </InfoSection>

          <Grid className="landing-page" fullWidth>
        <Column lg={16} md={8} sm={4} className="landing-page__r2">

        <Grid className="tabs-group-content">
      <Column md={4} lg={7} sm={4}>
      <img
          className="landing-page__illo"
          src={`${process.env.PUBLIC_URL}/smart-city.jpg`}
          alt="Carbon illustration"
      />
      </Column>
      <Column md={4} lg={{ span: 8, offset: 7 }} sm={4} className="landing-page__tab-content">
          <h1 className="landing-page__subheading">Empowering organizations<br /> with location intelligence</h1>
          <p className="landing-page__p">
            Government agencies, private organizations and non-profit organizations use GeoPsy Research's geospatial
            technology solutions to model and understand both global and local water crisis, food security, mineral exploration
            and climate change.

          </p>
          <Button as={Link} to="/about" >Learn more</Button>
        </Column>
      </Grid>
      </Column>
      <Column lg={16} md={8} sm={4} className="landing-page__r3">
  <Grid>
    <Column  md={4} lg={4}>
      <h3 className="landing-page__label">Our Key Themes</h3>
    </Column>
    <Column md={4} lg={4}>
      Urban Remote Sensing
    </Column>
    <Column md={4} lg={4}>
      Food Security
    </Column>
    <Column md={4} lg={4}>
      Citizen Science
    </Column>
  </Grid>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r2">

<Grid className="tabs-group-content">
<Column md={4} lg={7} sm={4} className="landing-page__tab-content">
  <h1 className="landing-page__subheading">Pattern extraction<br /> with machine learning</h1>
  <p className="landing-page__p">
  Are you tired of spending countless hours analyzing and interpreting your geospatial data? 
  Do you want to leverage the power of machine learning to gain new insights and make data-driven decisions 
  for your business? Our team of data experts can help you harness the full potential of your geospatial data 
  using advanced machine learning techniques. 
  With our cutting-edge tools and expertise, we can help you extract valuable insights, predict future trends, and optimize your operations.

  </p>
  <Button as={Link} to="/about" >Learn more</Button>
</Column>
<Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
<img
  className="landing-page__illo"
  style={{marginTop: 10}}
  src={`${process.env.PUBLIC_URL}/histograms.png`}
  alt="Carbon illustration"
/>
</Column>
</Grid>
</Column>

<Column lg={16} md={8} sm={4} className="landing-page__r2">

<Grid className="tabs-group-content">
<Column md={4} lg={7} sm={4}>
<img
  className="landing-page__illo"
  src={`${process.env.PUBLIC_URL}/spatial-imaging.png`}
  alt="Carbon illustration"
/>
</Column>
<Column md={4} lg={{ span: 8, offset: 7 }} sm={4} className="landing-page__tab-content">
  <h1 className="landing-page__subheading">Spatial Imaging<br /> with remote sensing</h1>
  <p className="landing-page__p">
    Leading organizations rely on GeoPsy Research's solutions to deliver high-resolution spatial imaging and cost-effective
    and reliable means for collecting spatial data remotely, either for business or research purposes.Our team of remote sensing experts can 
    help you acquire the spatial imagery you need using advanced satellite 
  and aerial platforms. Whether you need to map land use, monitor vegetation, or assess environmental impacts, 
  we have the tools and expertise to deliver the data you need. 

  </p>
  <Button as={Link} to="/about" >Learn more</Button>
</Column>
</Grid>
</Column>
      </Grid>
      <div className="landing-page__r5">
      <Partners />
      </div>
          </>
    )
}

export default LandingPage;