import { createStyles, Text, TextInput, Button, Notification } from '@mantine/core';
import { IconCheck } from '@tabler/icons';
import axios from 'axios';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xl * 2,
    borderRadius: theme.radius.md,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing.xl,
    },
  },

  image: {
    maxWidth: '40%',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  body: {
    paddingRight: theme.spacing.xl * 4,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: 'flex',
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: '100%',
    flex: '1',
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function Subscribe() {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [s, setS] = useState(false);

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        return (true)
    }
        return (false)
    }

  const subscribeUser = () => {
    if(email === ""){
        alert("Your email is required to subscribe!");
        return false;
    }

    if(!ValidateEmail(email)){
        alert("Your email is inavlid!");
        return false;
    }

    setLoading(true);

    axios.post("http://server.geopsyresearch.org/mail/send", {
        message: `Please record ${email} as a customer subscriber`,
        subject: "New Subscriber",
        firstname: "x",
        lastname: "y",
        email: email,
        toEmail: "daviskitavi@geopsyresearch.org",
        name: "David"
    }).then(function(res){
        if(res.status === 200){
            setLoading(false);
            setS(true);

            setTimeout(function(){setS(false)}, 60000);
        }
    }).catch(function(error){
        console.log(error);
    })
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <Text weight={500} size="lg" mb={5}>
          Subscribe to our newsletter!
        </Text>
        <Text size="sm" color="dimmed">
        Stay up to date with the latest industry news and trends! 
        Subscribe to our newsletter and receive exclusive content and offers straight to your inbox. 
        Click the button below to sign up and start receiving our newsletter today!
        </Text>
        {s ? (
            <Notification mt={10} icon={<IconCheck size={18} />} onClose={() => {setS(false)}} color="teal" title="Teal notification">
            You have successfully subscribed to our newsletter.
          </Notification>
        ) : null}
        <div className={classes.controls}>
          <TextInput
            placeholder="Your email"
            classNames={{ input: classes.input, root: classes.inputWrapper }}
            value={email}
            onChange={(e) => {setEmail(e.currentTarget.value)}}
          />
          <Button loading={loading} onClick={() => {subscribeUser()}} className={classes.control}>{s ? "Subscribed" : "Subscribe"}</Button>
        </div>
      </div>
    </div>
  );
}