import React, { useState } from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  ComposedModal,
  ModalFooter,
  Button,
  ModalBody,
  ModalHeader,
  TextInput,
  Grid,
  Column,
  TextArea,
  Loading
} from '@carbon/react';
import { Wikis, Search } from '@carbon/react/icons';
import axios from 'axios';

const AppHeader = () => {
    const [opened, setOpened] = useState(false);
    const [opened2, setOpened2] = useState(false);

    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [error1, setError1] = useState({error: false, message: ""});
    const [error2, setError2] = useState({error: false, message: ""});
    const [error3, setError3] = useState({error: false, message: ""});
    const [error5, setError5] = useState({error: false, message: ""});
    const [error6, setError6] = useState({error: false, message: ""});

    const [active, setActive] = useState(false);

    const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        return (true)
    }
        return (false)
    }


    const sendMessage = () => {
        if(first === ""){
            setError1({error: true, message: "Your firstname is required"});
            return false;
        }

        if(last === ""){
            setError2({error: true, message: "Your last name is required"});
            return false;
        }

        if(email === ""){
            setError3({error: true, message: "Your email is required."});
            return false;
        }

        if(!ValidateEmail(email)){
            setError3({error: true, message: "Your email is invalid."});
            return false;
        }

        if(subject === ""){
            setError5({error: true, message: "Provide subject for support."});
            return false;
        }


        if(message === ""){
            setError6({error: true, message: "Provide detailed message for inquiry."});
            return false;
        }

        setActive(true);

        axios.post("http://server.geopsyresearch.org/mail/send", {
            message: message,
            firstname: first,
            lastname: last,
            email: email,
            subject: subject,
            toEmail: "oloofrank@gmail.com",
            name: "Dr. Francis",
        }).then(function(res){
            if(res.status === 200){
                setOpened2(false);
                setActive(false);
                setFirst("");
                setLast("");
                setEmail("");
                setSubject("");
                setMessage("");
                setError1({error: false, message: ""})
                setError2({error: false, message: ""})
                setError3({error: false, message: ""})
                setError5({error: false, message: ""})
                setError6({error: false, message: ""})
            }
        })

    };

    return (
        <>
        <HeaderContainer
          render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <Header aria-label="GeoPsy Research">
              <SkipToContent />
              <HeaderMenuButton
                aria-label="Open menu"
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
              />
              <HeaderName element="a" href="/" prefix="">
                GeoPsy Research
              </HeaderName>
              <HeaderNavigation aria-label="Products">
              <HeaderMenuItem element="a" href="/products">Products</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="/industries">Industries</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="/about">About GeoPsy Research</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="https://geopsyresearch.org/blogs/">Stories</HeaderMenuItem>
              </HeaderNavigation>
              <SideNav
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
                isPersistent={false}
              >
                <SideNavItems>
                  <HeaderSideNavItems>
                  <HeaderMenuItem element="a" href="/products">Products</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="/industries">Industries</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="/about">About GeoPsy Research</HeaderMenuItem>
                  <HeaderMenuItem element="a" href="https://geopsyresearch.org/blogs/">Stories</HeaderMenuItem>
                  </HeaderSideNavItems>
                </SideNavItems>
              </SideNav>
              <HeaderGlobalBar>
              <HeaderGlobalAction  onClick={() => {setOpened(true)}} aria-label="Search" tooltipAlignment="center">
                  <Search size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction onClick={() => {setOpened2(true)}} aria-label="Support" tooltipAlignment="end">
                  <Wikis size={20} />
              </HeaderGlobalAction>
              </HeaderGlobalBar>
            </Header>
          )}
        />
        <ComposedModal open={opened} onClose={() => {setOpened(false)}}>
  <ModalBody hasForm>
    <TextInput id="search-query" data-modal-primary-focus labelText="" />
    <p style={{marginTop: 20}} className="bx--modal-content__text bx--modal-content__regular-content">
            No result found
    </p>
  </ModalBody>
        <ModalFooter>
    <Button
      kind="secondary"
      onClick={() => {setOpened(false); }}>
      Cancel
    </Button>
    <Button
      kind="primary"
      onClick={() => { }}>
      Search
    </Button>
  </ModalFooter>
        </ComposedModal>

        <ComposedModal open={opened2} onClose={() => {setOpened2(false)}} >
  <ModalBody hasForm>
            <Loading small={true} active={active} />
        <TextInput id="first-name" className="landing-page__r4" invalid={error1.error} invalidText={error1.message} onChange={(e) => {setFirst(e.target.value)}} required data-modal-primary-focus labelText="First Name" />

        <TextInput id="last-name" className="landing-page__r4" invalid={error2.error} invalidText={error2.message} onChange={(e) => {setLast(e.target.value)}} data-modal-primary-focus labelText="Last Name" />

        <TextInput id="email" className="landing-page__r4" invalid={error3.error} invalidText={error3.message} onChange={(e) => {setEmail(e.target.value)}} data-modal-primary-focus labelText="Email Address" />

        <TextInput id="subject" className="landing-page__r4" invalid={error5.error} invalidText={error5.message} onChange={(e) => {setSubject(e.target.value)}} data-modal-primary-focus labelText="Subject" />

        <TextArea id="message" className="landing-page__r4" invalid={error6.error} invalidText={error6.message} onChange={(e) => {setMessage(e.target.value)}} data-modal-primary-focus labelText="Message" />

  </ModalBody>
        <ModalFooter>
    <Button
      kind="secondary"
      onClick={() => {setOpened2(false); }}>
      Cancel
    </Button>
    <Button
      kind="primary"
      onClick={() => { sendMessage(); }}>
      Send Message
    </Button>
  </ModalFooter>
        </ComposedModal>
        </>
      );
}

export default AppHeader;