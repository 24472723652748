import React from "react";
import { Grid, Column} from "@carbon/react";
import ProductList from "../../components/Products";
import { Helmet } from "react-helmet";
const Products = () => {
    return (
        <>
                <Helmet>
            <title>GeoPsy Research | Products</title>
            <meta name="description" content="GeoPsy Research is the leading center for research and training on socio-spatial vulnerabilities, mental health, geospatial analytics, and surveying measurements" />
        </Helmet>
        <Grid className="landing-page" fullWidth>
                    <Column lg={16} md={8} sm={4} className="landing-page__r2">

<Grid className="tabs-group-content">
<Column md={4} lg={7} sm={4} className="landing-page__tab-content">
  <h1 className="landing-page__subheading">GeoPsy Research<br /> Products</h1>
  <p className="landing-page__p">
  GeoPsy Research builds the leading mapping and spatial analytics software designed to support the mission and business objectives of organizations around the globe,
   regardless of size. Our products run in the cloud, on mobile devices, and on desktops. 
  Explore various product offerings that match your business needs.
  </p>
</Column>
<Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
<img
  className="landing-page__illo"
  src={`${process.env.PUBLIC_URL}/products.png`}
  alt="Carbon illustration"
/>
</Column>
</Grid>
</Column>
        </Grid>
        <ProductList />
        </>
    )
}

export default Products;