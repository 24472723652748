import {
    Grid,
    Column
  } from '@carbon/react';
  import {
    Card,
    Text,
    SimpleGrid,
    createStyles,
  } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  wrapper: {
      margin: 10,
      marginBottom: 0,
      marginTop: 50,
      paddingBottom: 50,
      overflowX: "auto"
  },

title: {
  fontSize: 34,
  fontWeight: 900,
  [theme.fn.smallerThan('sm')]: {
    fontSize: 24,
  },
},

description: {
  maxWidth: 600,
  margin: 'auto',

  '&::after': {
    content: '""',
    display: 'block',
    backgroundColor: theme.fn.primaryColor(),
    width: 45,
    height: 2,
    marginTop: theme.spacing.sm,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
},

card: {
  marginBottom: 20,
  border: `1px solid ${
    theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
  }`,
},

cardTitle: {
  '&::after': {
    content: '""',
    display: 'block',
    backgroundColor: theme.fn.primaryColor(),
    width: 45,
    height: 2,
    marginTop: theme.spacing.sm,
  },
},
}));
const partners = [
  {
    img: "aceli.svg",
    alt: "Aceli Africa"
  },
  {
    img: "shanta-gold.svg",
    alt: "Shanta Gold"
  },
  {
    img: "emita.jpg",
    alt: "Emita"
  },
  {
    img: "mindful.png",
    alt: "Mindful African"
  },
  {
    img: "Aics.png",
    alt: "AICS"
  }
]

export function Feature({img, alt}){
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  return (
    <img src={`${process.env.PUBLIC_URL}/${img}`} alt={`${alt}`} width={width * 0.8 * 1/6} height="35" />
  )
}

export default function Partners(){
  const { classes, theme } = useStyles();
  const features = partners.map((feature, index) => <Feature {...feature} key={index} />);
  const { height, width} = useViewportSize();
    return (
      <div className={classes.wrapper} >
      <SimpleGrid
        mt={10}
        cols={6}
        spacing={theme.spacing.xs}
      >
        <div style={{width : width * 0.8 * 1/6, marginLeft: 20}} >
        <p >Our Partners</p>
        </div>
        {features}
      </SimpleGrid>
    </div>
    )
}