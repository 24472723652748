import React from "react";
import {
    Grid,
    Column
  } from '@carbon/react';
import Partners from "../../components/Partners";
import { Helmet } from "react-helmet";

const About = () => {
    return (
        <>
        <Helmet>
            <title>About GeoPsy Research | Mapping Minds</title>
            <meta name="description" content="GeoPsy Research is the leading center for research and training on socio-spatial vulnerabilities, mental health, geospatial analytics, and surveying measurements" />
        </Helmet>
        <Grid className="landing-page" fullWidth>
                    <Column lg={16} md={8} sm={4} className="landing-page__r2">

<Grid className="tabs-group-content">
<Column md={4} lg={7} sm={4} className="landing-page__tab-content">
  <h1 className="landing-page__subheading">Our Mission</h1>
  <p className="landing-page__p">
  To undertake novel multidisciplinary research, implement relevant trainings and capacity building events, and design effective scientific communication outputs for the development of innovative and sustainable solutions for environmental and social vulnerabilities in Africa.
  </p>
</Column>
<Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
<img
  className="landing-page__illo"
  src={`${process.env.PUBLIC_URL}/mission.png`}
  alt="Carbon illustration"
/>
</Column>
</Grid>
</Column>

<Column lg={16} md={8} sm={4} className="landing-page__r2">

<Grid className="tabs-group-content">
<Column md={4} lg={7} sm={4}>
<img
  className="landing-page__illo"
  src={`${process.env.PUBLIC_URL}/image7.png`}
  alt="Carbon illustration"
/>
</Column>
<Column md={4} lg={{ span: 8, offset: 7 }} sm={4} className="landing-page__tab-content">
  <h1 className="landing-page__subheading">Our Vision</h1>
  <p className="landing-page__p">
  To be a leading center for research, consultancy and training in geospatial sciences and mental health literacy in Kenya; combining knowledge and methods from spatial sciences, statistics, environmental sciences, and psychology among other disciplines to enhance public literacy and develop innovative and sustainable solutions for addressing environmental and social vulnerabilities.

  </p>
</Column>
</Grid>
</Column>
        </Grid>
        <Partners />
        </>
    )
}

export default About;